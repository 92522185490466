'use client';

import { ErrorPageContentClient } from '@module/mdrt-org/shared/page-content-components';

type ErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

const Error = ({ error, reset }: ErrorPageProps) => {
  return <ErrorPageContentClient message={error.message} reset={reset} />;
};

export default Error;
